










import {Component, Vue} from 'vue-property-decorator';
import MainContent from '@/components/default/MainContent.vue';
import G3wButton from '@/components/default/g3wButton.vue';
import TabWidget from '@/components/TabWidget.vue';

@Component({
    components: {G3wButton, MainContent, TabWidget},
})

export default class MapsContent extends Vue {

}
