export const en: any = {
    messages: {
        hello: 'hello',
        tooltip: {
          cambia_lingua: 'Change language',
          home: 'Go to home',
          logout: 'Logout',
          login: 'Login',
          admin: 'Admin section',
        },
        menu: {
            cerca: 'SEARCH',
            home: 'home',
            mappe: 'maps',
            info: 'info',
            news: 'news',
            archivi: 'archives',
            login: 'login',
            logout: 'logout',
        },
        login: {
            username: 'Username',
            password: 'Password',
            accesso: 'Login',
            titolo: 'Accesso al sistema di amministrazione',
            reset_password_url: "Forgot your password?"
            // sottotitolo: 'Quest\'area è riservata agli amministratori e gestori del sistema.',
        },
        // home: {
        //     titolo: 'Lorem ipsum dolor sit amet,\n' +
        //         'consectetur adipiscing elit.',
        //     sottotitolo: 'Sed do eiusmod tempor incididunt',
        // },
        tab: {
            firstTab: 'Home',
            // titolo: 'Lorem ipsum dolor sit amet.',
            // sottotitolo: 'Sottotitolo',
            // descrizione: 'In questa sezione puoi trovare una serie di mappe tematiche che riguardano la\n' +
            //     'biodiversità, la storia e la gestione del Parco Nazionale. Molte di queste contengono utili\n' +
            //     'informazioni alle quali potrai accedere\n' +
            //     'con il semplice utilizzo del mouse. Le mappe sono in continuo aggiornamento con i nuovi dati in\n' +
            //     'possesso del Parco e la sezione verrà sempre arricchita da nuovi contenuti.\n' +
            //     'Scegli l\'area tematica di tuo interesse e scopri tutte le informazioni su mappa che riguardano\n' +
            //     'l\'Area protetta.',
        },
        about: {
            chisiamo: 'chi siamo',
            telefono: 'contatti telefonici',
            email: 'indirizz email',
            dovesiamo: 'dove siamo',
        },
        language: {
            it: 'ita',
            en: 'eng',
        },
    },
};
