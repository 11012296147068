





























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    components: {},
})
export default class g3wButton extends Vue {
    @Prop(String) private readonly icon!: string;
    @Prop(String) private readonly size!: string;
    @Prop(String) private readonly text!: string;
    @Prop(String) private readonly to!: string;
    @Prop(String) private readonly hoverClasses!: string;
    @Prop(Boolean) private readonly alwaysExpanded!: boolean;

    private show_text: boolean = false;
    private text_: string = this.text || '';
    private hover_: boolean = false;

    private mouseOver() {
        if (this.text_.length) {
            this.show_text = true;
        }
        this.hover_ = true;

    }

    get isHover() {
        return this.hover_;
    }

    private mouseOut() {
        this.hover_ = false;
    }

    get showText() {
        return this.show_text || this.expanded;
    }

    private mouseLeave() {
        if (this.text_.length) {
            this.show_text = false;
        }
    }

    get expanded() {
        return this.$route.name === this.to || this.alwaysExpanded;
    }
}
