

















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    components: {},
})

export default class InfoWidget extends Vue {
    @Prop(String) private readonly icon!: string;
    @Prop(String) private readonly size!: string;
    @Prop(String) private readonly text!: string;
}
