

































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {EBoxType} from '@/datastore/interfaces/RequestsInterfaces';

@Component({
    components: {},
})
export default class TabBox extends Vue {

    public eboxtype = EBoxType;
    @Prop(String) private readonly title!: string;
    @Prop(String) private readonly href!: string;
    @Prop(String) private readonly img_url!: string;
    @Prop(Number) private readonly type!: number;
    @Prop(Number) private readonly id!: number;
    @Prop(String) private readonly edit_url!: string;
    @Prop(String) private readonly map_url!: string;
    @Prop(String) private readonly description!: string;

    private expand(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.$store.dispatch('modal/setTitle', {title: this.title || ''});
        this.$store.dispatch('modal/setUrl', {url: this.img_url || ''});
        this.$bvModal.show('thumbnailModal');
    }

    // get bg_img() {
    //     return {
    //         backgroundImage: "url('" + this.img_url + "')",
    //     }
    // }
}
