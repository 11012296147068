









































































import {Component, Prop, Vue} from 'vue-property-decorator';
import InfoCard from '@/components/InfoCard.vue';

@Component({
    components: {InfoCard},
})
export default class AboutPA extends Vue {
    get about() {
        return this.$store.getters['info/info'];
    }

    // public created() {
    //     this.$store.dispatch('info/fetchInfo');
    // }

    get telHref() {
        return 'tel:' + this.about.about_tel;
    }

    get emailHref() {
        return 'to:' + this.about.about_email;
    }
}
