













import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    components: {},
})
export default class InfoCard extends Vue {
    @Prop(String) private readonly title!: string;
    @Prop(String) private readonly text!: string;

}
