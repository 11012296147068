
















import {Component, Vue} from 'vue-property-decorator';
import MainContent from '@/components/default/MainContent.vue';
import Login from '@/components/Login.vue';

@Component({
    components: {MainContent, Login},
})

export default class LoginContent extends Vue {
}
