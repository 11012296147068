












import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    components: {},
})

export default class TabButton extends Vue {
    @Prop(Boolean) private readonly active!: boolean;
    @Prop(String) private readonly text!: string;
    @Prop(String) private readonly activeClass!: string;
    @Prop({required: true, type: Number}) private readonly idx!: number;
}
