






























































import {Component, Prop, Vue} from 'vue-property-decorator';
import TabButton from '@/components/TabButton.vue';
import {Group, IGroupDict} from '@/datastore/types/Group';
import {IMacroGroupDict, MacroGroup} from '@/datastore/types/MacroGroup';
import TabBox from '@/components/TabBox.vue';
import {EBoxType} from '@/datastore/interfaces/RequestsInterfaces';
import {SuperGroup} from '@/datastore/types/SuperGroup';
import TabNav from '@/components/TabNav.vue';
import {mapGetters} from 'vuex';
import {Info} from '@/datastore/types/Info';
import Search from '@/components/Search.vue';

// groups with no macrogroups and macrogroups

interface IGWNM_AND_MG_Dict {
    [key: string]: IGroupDict | IMacroGroupDict;
}

@Component({
    components: {TabNav, TabBox, TabButton, Search},
    computed: {
        ...mapGetters({
            settings: 'info/info',
        }),
    },
})

export default class TabWidget extends Vue {

    get search() {
        return this.$store.getters['group/search'];
    }

    set search(val: string) {
        this.$store.dispatch('group/search', {s: val});
    }

    get boxes() {
        if (this.search) {
            return this.$store.getters['group/fitleredProjects'];
        }
        // se sono nel primo tab
        const els: Array<MacroGroup | Group> = [];
        if (this.tabs.length == 1) {
            let obj = this.$store.getters['group/macroGroups'];
            for (const i in obj) {
                els.push(obj[i] as MacroGroup);
            }
            obj = this.$store.getters['group/groupsWithNoMacroGroup'];
            for (const i in obj) {
                els.push(obj[i] as Group);
            }
            return els;
        } else if (this.tabs.length > 1) {
            const activeEl = this.stackElementTab[this.stackElementTab.length - 1];
            if (activeEl.InstanceOf == EBoxType.MG) {
                return (activeEl as MacroGroup).Groups;
            }
            if (activeEl.InstanceOf == EBoxType.G) {
                return (activeEl as Group).Projects;
            }
        }
    }

    get title() {
        if (this.tabs.length == 1) {
            return this.settings.groups_title;
        }
        return this.$store.getters['group/activeGroup'].title;
    }

    get description() {
        if (this.tabs.length == 1) {
            return this.settings.groups_map_description;
        }
        return this.$store.getters['group/activeGroup'].description;
    }
    public eboxtype = EBoxType;

    public boxtype = EBoxType;

    private is_pa = (window as any).IS_PA || false;

    @Prop(String) private readonly activeClass!: string;

    private tabs: string[] = [];
    private stackElementTab: SuperGroup[] = [];
    private settings!: Info;

    public mounted() {
        this.$store.dispatch('group/fetchMacroGroups', {locale: this.$i18n.locale});
        this.$store.dispatch('group/fetchGroupsWithNoMacroGroup', {locale: this.$i18n.locale});
        this.$store.dispatch('group/fetchProjects', {locale: this.$i18n.locale});
    }

    public created() {
        this.tabs = [this.$tc('messages.tab.firstTab')];
    }

    private handleTabClick(idx: number) {
        this.tabs.splice(idx + 1, this.tabs.length);
        this.stackElementTab.splice(idx, this.stackElementTab.length);
        this.$store.dispatch('group/setActiveGroup', {sg: this.stackElementTab[this.stackElementTab.length - 1]});
    }

    private handleBoxClick(id: number, type: EBoxType) {
        let el: SuperGroup = new SuperGroup();
        if (type == EBoxType.P) {
            return false;
        }
        switch (type) {
            case EBoxType.MG:
                //console.log(this.$store.getters['group/macroGroups'])
                el = Object.values(this.$store.getters['group/macroGroups']).find(mg => mg.id === id);
                (el as MacroGroup).fetchGroups();
                break;
            case EBoxType.G:
                el = Object.values(this.$store.getters['group/groups']).find(gp => gp.id === id);
                (el as Group).fetchProjects();
                break;
        }

        this.$store.dispatch('group/setActiveGroup', {sg: el});
        this.stackElementTab = this.stackElementTab.concat(el);
        // @ts-ignore
        this.tabs = this.tabs.concat(el.title);
    }
}
