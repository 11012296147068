

















import {Component, Prop, Vue} from 'vue-property-decorator';
import TabButton from '@/components/TabButton.vue';

@Component({
    components: {TabButton},
})

export default class TabNav extends Vue {
    @Prop({type: Array, required: true}) private readonly tabs!: string[];
    @Prop(String) private readonly activeClass!: string;

}
