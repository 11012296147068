








































import {Component, Prop, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
    components: {},
    computed: {
        ...mapGetters({
            pictures: 'settings/pictures',
        }),
    },
})

export default class AboutContent extends Vue {
    private index: number = 0;

    public mounted() {
        window.setInterval(() => {
            this.index++;
        }, 30000);
    }
}
